
import {defineComponent, watch} from 'vue';
import {mapActions, mapGetters} from "vuex";
import {useRoute} from "vue-router";
import Loading from "@/components/Loading.vue";
import {LoadingState} from "@/models/api/v1/LoadingState";
import {Api} from "@/types/api/v1/result";
import Variant = Api.V1.Testing.Variant;
import Question = Api.V1.Testing.Question;

export enum CorrectnessState {
  ID_DEFAULT = 'default',
  ID_SUCCESS = 'success',
  ID_DANGER = 'danger',
}


enum QuestionType {
  ID_CHECKBOX = 10,
  ID_RADIO = 20,
  ID_CARDS_RADIO = 30,
  ID_CARDS_CHECKBOX = 40,
}

export default defineComponent({
  name: 'Result',
  components: {Loading},
  setup() {
    const route = useRoute()

    return {
      // with params.id
      id: route.params.id
    }
  },
  methods: {
    ...mapActions({
      catchError: 'catchError',
      loadResult: 'loadResult',
    }),
    ...mapGetters({}),
    getVariantColor(question: Question, variant: Variant): string {
      if (!variant.variant_is_selected) {
        return '';
      }
      switch (question.question_correctness_state_id) {
        case CorrectnessState.ID_DANGER:
          return 'variant-invalid uk-text-danger uk-text-bold'
        case CorrectnessState.ID_SUCCESS:
          return 'variant-valid uk-text-success uk-text-bold'
        default:
          return '';
      }
    },
    isCheckbox(question: Question) {
      return [QuestionType.ID_CHECKBOX, QuestionType.ID_CARDS_CHECKBOX].indexOf(Number(question.question_type_id)) !== -1;
    },

    isRadio(question: Question) {
      return [QuestionType.ID_RADIO, QuestionType.ID_CARDS_RADIO].indexOf(Number(question.question_type_id)) !== -1;
    },
    createVerdict(question: Question) {
      if (question.question_correctness_state_id == CorrectnessState.ID_SUCCESS) {
        return `<span class="uk-text-success success-message"><span uk-icon="icon: check; ratio:0"></span>${question.question_correctness_result_message}</span>`;
      }

      if (question.question_correctness_state_id == CorrectnessState.ID_DANGER) {
        return `<div class="uk-text-danger uk-text-large error-message"><span uk-icon="icon: ban; ratio:0"></span> ${question.question_correctness_result_message}</div>
                <div class="uk-text-danger uk-text-default">Для того что бы ответ считался правильным - нужно выбрать <b>все верные варианты</b></div>
`;
      }

      return '';

    },
  },


  computed: {
    LoadingState() {
      return LoadingState
    },
    ...mapGetters({
      resultLoadingState: 'resultLoadingState',
      resultPageTitle: 'resultPageTitle',
      result: 'result',

    }),

  },
  mounted() {
    this.loadResult(this.id);
    window.document.title = this.resultPageTitle;

    watch(() => this.resultPageTitle, () => {
      window.document.title = this.resultPageTitle;
    });

  },


})
